<template>
  <div id="plan">
    <el-dialog
      :title="planFormTitle"
      width="1200px"
      :visible.sync="planDialogVisible"
      :close-on-click-modal="false"
      @close="planDialogClose"
    >
      <div id="pdfDom">
        <el-form
          ref="planFormRef"
          :model="planForm"
          :rules="planFormRules"
          label-position="right"
          label-width="100px"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="计划类型" prop="dateType">
                <el-radio-group
                  v-model="planForm.dateType"
                  :disabled="
                    planFormTitle !== '新增生产计划表' &&
                    planFormTitle !== '修改生产计划表' &&
                    planFormTitle !== '生产计划表详情'
                  "
                >
                  <el-radio :label="1"> 年度 </el-radio>
                  <el-radio :label="2"> 季度 </el-radio>
                  <el-radio :label="3"> 月度 </el-radio>
                  <el-radio :label="4"> 周 </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="拟定时间" prop="dateValue">
                <el-input
                  v-model="planForm.dateValue"
                  placeholder="请输入拟定时间"
                  clearable
                  :disabled="
                    planFormTitle !== '新增生产计划表' &&
                    planFormTitle !== '修改生产计划表' &&
                    planFormTitle !== '生产计划表详情'
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <vxe-toolbar
              v-if="
                planFormTitle === '新增生产计划表' ||
                planFormTitle === '修改生产计划表'
              "
            >
              <template #buttons>
                <vxe-button @click="insertRowEvent()"> 新增 </vxe-button>
              </template>
            </vxe-toolbar>
            <vxe-table
              ref="xTable"
              border
              resizable
              show-overflow
              keep-source
              :data="planDetailList"
              :edit-config="{ trigger: 'click', mode: 'cell' }"
            >
              <vxe-table-column field="productNo" title="产品编号">
                <template v-slot="{ row, column }">
                  <el-select
                    v-model="row[column.property]"
                    placeholder="请选择产品"
                    clearable
                    filterable
                    @change="materialProductStockChange(row)"
                  >
                    <el-option
                      v-for="item in materialProductStockList"
                      :key="item.id"
                      :label="item.productNo"
                      :value="item.productNo"
                    />
                  </el-select>
                </template>
              </vxe-table-column>
              <vxe-table-column
                field="productName"
                title="产品名称"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <vxe-table-column
                field="spec"
                title="规格"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <vxe-table-column
                field="quantity"
                title="数量"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <vxe-table-column
                field="preproductionDate"
                title="预生产日期"
                :edit-render="{
                  name: '$input',
                  props: { type: 'date', clearable: true },
                }"
              />
              <vxe-table-column
                field="productionDeadline"
                title="生产截止日期"
                :edit-render="{
                  name: '$input',
                  props: { type: 'date', clearable: true },
                }"
              />
              <vxe-table-column
                field="remarks"
                title="备注"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <vxe-table-column
                v-if="
                  planFormTitle === '新增生产计划表' ||
                  planFormTitle === '修改生产计划表'
                "
                title="操作"
                width="100"
              >
                <template #default="{ row }">
                  <template>
                    <vxe-button @click="removeRowEvent(row)"> 删除 </vxe-button>
                  </template>
                </template>
              </vxe-table-column>
            </vxe-table>
          </el-form-item>
          <el-form-item label="批准结果" prop="reviewResult">
            <el-radio-group
              v-model="planForm.reviewResult"
              :disabled="
                planFormTitle !== '批准生产计划表' &&
                planFormTitle !== '生产计划表详情'
              "
            >
              <el-radio :label="1"> 通过 </el-radio>
              <el-radio :label="2"> 不通过 </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="planDialogVisible = false"> 取 消 </el-button>
        <el-button type="primary" @click="planFormSubmit"> 确 定 </el-button>
        <el-button
          v-if="planFormTitle === '生产计划表详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="拟定时间">
        <el-input
          v-model="searchForm.dateValue"
          placeholder="请输入拟定时间"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['PRODUCTION_PLAN_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="planPage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column label="计划类型">
        <template slot-scope="scope">
          <span v-if="scope.row.dateType === 1">年度</span>
          <span v-if="scope.row.dateType === 2">季度</span>
          <span v-if="scope.row.dateType === 3">月度</span>
          <span v-if="scope.row.dateType === 4">周</span>
        </template>
      </el-table-column>
      <el-table-column prop="dateValue" label="拟定时间" />
      <el-table-column prop="editor" label="编制人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.editDate">{{
            scope.row.editDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalDate">{{
            scope.row.approvalDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="批准结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="200" fixed="right">
        <template slot-scope="scope">
          <el-button
            v-if="
              checkPermission(['PRODUCTION_PLAN_DELETE']) &&
              scope.row.status === 1
            "
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="
              checkPermission(['PRODUCTION_PLAN_UPDATE']) &&
              scope.row.status === 1
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="
              checkPermission(['PRODUCTION_PLAN_APPROVAL']) &&
              scope.row.status === 1
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleApproval(scope.$index, scope.row)"
          >
            批准
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="planPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addPlan,
  deletePlan,
  updatePlan,
  selectPlanInfo,
  selectPlanList,
  approvalPlan,
} from "@/api/produce/plan";
import { selectMaterialProductStockList } from "@/api/storage/materialProductStock";
import html2PDF from "jspdf-html2canvas";
import { Loading } from "element-ui";

export default {
  data() {
    return {
      planDialogVisible: false,
      planFormTitle: "",
      planForm: {
        id: "",
        dateType: "",
        dateValue: "",
        status: "",
        reviewResult: "",
        planDetail1Json: "",
        taskId: "",
      },
      planFormRules: {
        dateValue: [
          {
            required: true,
            message: "拟定时间不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      planPage: {
        list: [],
        total: 0,
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        dateValue: "",
      },
      planDetailList: "",
      materialProductStockList: [],
    };
  },
  created() {
    selectPlanList(this.searchForm).then((res) => {
      this.planPage = res;
    });
    selectMaterialProductStockList({ type: 1 }).then((res) => {
      this.materialProductStockList = res.list;
    });
  },
  methods: {
    planDialogClose() {
      this.$refs.planFormRef.resetFields();
      this.planDetailList = [];
    },
    planFormSubmit() {
      if (this.planFormTitle === "生产计划表详情") {
        this.planDialogVisible = false;
        return;
      }
      this.$refs.planFormRef.validate(async (valid) => {
        if (valid) {
          if (this.planFormTitle === "新增生产计划表") {
            this.planForm.id = "";
            this.planForm.status = 1;
            this.planForm.planDetail1Json = JSON.stringify(
              this.$refs.xTable.getTableData().tableData
            );
            await addPlan(this.planForm);
          } else if (this.planFormTitle === "修改生产计划表") {
            this.planForm.planDetail1Json = JSON.stringify(
              this.$refs.xTable.getTableData().tableData
            );
            await updatePlan(this.planForm);
          } else if (this.planFormTitle === "批准生产计划表") {
            this.planForm.status = 2;
            await approvalPlan(this.planForm);
          }
          this.planPage = await selectPlanList(this.searchForm);
          this.planDialogVisible = false;
        }
      });
    },
    handleAdd() {
      this.planFormTitle = "新增生产计划表";
      this.planDialogVisible = true;
    },
    handleDelete(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        await deletePlan(row.id);
        if (this.planPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--;
        }
        this.planPage = await selectPlanList(this.searchForm);
      });
    },
    handleUpdate(index, row) {
      this.planFormTitle = "修改生产计划表";
      this.planDialogVisible = true;
      this.selectPlanInfoById(row.id);
    },
    handleApproval(index, row) {
      this.planFormTitle = "批准生产计划表";
      this.planDialogVisible = true;
      this.selectPlanInfoById(row.id);
    },
    handleInfo(index, row) {
      this.planFormTitle = "生产计划表详情";
      this.planDialogVisible = true;
      this.selectPlanInfoById(row.id);
    },
    selectPlanInfoById(id) {
      selectPlanInfo(id).then((res) => {
        this.planForm.id = res.id;
        this.planForm.dateType = res.dateType;
        this.planForm.dateValue = res.dateValue;
        this.planForm.status = res.status;
        this.planForm.reviewResult = res.reviewResult;
        this.planForm.taskId = res.taskId;
        this.planDetailList = res.planDetailList;
      });
    },
    handleSearch() {
      this.searchForm.pageNum = 1;
      selectPlanList(this.searchForm).then((res) => {
        this.planPage = res;
      });
    },
    pageNumChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      selectPlanList(this.searchForm).then((res) => {
        this.planPage = res;
      });
    },
    pageSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      selectPlanList(this.searchForm).then((res) => {
        this.planPage = res;
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (
        this.checkPermission(["PRODUCTION_PLAN_APPROVAL"]) &&
        row.status === 1
      ) {
        return "review";
      }
      return "";
    },
    async insertRowEvent(row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1);
      await this.$refs.xTable.setActiveRow(newRow);
    },
    removeRowEvent(row) {
      this.$refs.xTable.remove(row);
    },
    materialProductStockChange(row) {
      if (row.productNo) {
        let materialProductStock = this.materialProductStockList.find(
          (item) => item.productNo === row.productNo
        );
        row.productName = materialProductStock.productName;
        row.spec = materialProductStock.spec;
      } else {
        row.productName = "";
        row.spec = "";
      }
    },
    getPdf() {
      let loading = "";
      let dom = document.getElementById("pdfDom");
      html2PDF(dom, {
        jsPDF: {
          unit: "px",
          format: "a4",
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true,
        },
        imageType: "image/jpeg",
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0,
        },
        output: `生产计划表${this.planForm.dateValue.substring(0, 10)}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: "pdf加载中",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
        },
        success: function (pdf) {
          pdf.save(this.output);
          loading.close();
        },
      });
    },
  },
};
</script>

<style></style>
